var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.isNotLoggedIn === true &&
    !["invgen-single", "login", "invitation-review"].includes(_vm.$route.name)
    ? _c(
        "v-footer",
        { staticClass: "white--text secondary" },
        [
          _c("v-container", { staticClass: "py-0 my-0" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _c(
                  "a",
                  {
                    staticClass: "caption ml-4",
                    staticStyle: { "text-decoration": "none", color: "white" },
                    attrs: { href: "http://alphacruncher.com/legal" }
                  },
                  [_vm._v("Legal")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "caption ml-4",
                    staticStyle: { "text-decoration": "none", color: "white" },
                    attrs: { href: "mailto:support@alphacruncher.com" }
                  },
                  [_vm._v("Contact Us")]
                ),
                _c(
                  "router-link",
                  {
                    staticStyle: { "text-decoration": "none", color: "white" },
                    attrs: { to: { name: "privacy-policy" } }
                  },
                  [
                    _c("span", { staticClass: "caption ml-4 my-0 pa-0" }, [
                      _vm._v("Privacy Policy")
                    ])
                  ]
                ),
                _c("v-spacer"),
                _c("span", { staticClass: "caption" }, [
                  _vm._v("© 2019 Alphacruncher AG. All Rights Reserved")
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }