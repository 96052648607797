<template>
    <v-footer
        class="white--text secondary"
        v-if="$store.state.isNotLoggedIn === true && !['invgen-single', 'login', 'invitation-review'].includes($route.name)"
    >
        <v-container class="py-0 my-0">
            <div class="d-flex flex-wrap align-center">
                <a href="http://alphacruncher.com/legal" style="text-decoration: none; color: white;" class="caption ml-4">Legal</a>
                <a href="mailto:support@alphacruncher.com" style="text-decoration: none; color: white" class="caption ml-4">Contact Us</a>
                <router-link style="text-decoration: none; color: white" :to="{ name: 'privacy-policy' }">
                    <span class="caption ml-4 my-0 pa-0">Privacy Policy</span></router-link
                >
                <v-spacer />
                <span class="caption">© 2019 Alphacruncher AG. All Rights Reserved</span>
            </div>
        </v-container>
    </v-footer>
</template>
<script>
export default {}
</script>
